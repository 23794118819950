.otp .options.profile .itin-body .place-row {
  margin-left: 55px;
}

.otp .line-itin {
  margin-bottom: 20px;
}

/* Itinerary summary */

.otp .line-itin .itin-summary {
  padding-right: 5px;
  height: 60px;
  display: table;
  width: 100%;
  margin-bottom: 15px;
}

.otp .desktop-narrative-container .options.itinerary .line-itin .itin-summary {
  display: none;
}

.otp .line-itin .itin-summary .details {
  display: table-cell;
  vertical-align: top;
}

.otp .line-itin .itin-summary .header {
  font-weight: bold;
  font-size: 18px;
  margin-top: -3px;
}

.otp .line-itin .itin-summary .detail {
  font-size: 13px;
  color: #999999;
}

.otp .line-itin .itin-summary .routes {
  display: table-cell;
  text-align: right;
}

.otp .line-itin .itin-summary .routes .route-preview {
  display: inline-block;
  margin-left: 8px;
  vertical-align: top;
}

.otp .line-itin .itin-summary .routes .route-preview .mode-icon {
  height: 30px;
  width: 30px;
}

.otp .line-itin .itin-summary .routes .route-preview .short-name {
  color: white;
  font-weight: 500;
  text-align: center;
  margin-top: 6px;
  font-size: 15px;
  padding-top: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 2px solid white;
  box-shadow: 0 0 0.5em #000;
}

/* Itinerary main body */

.otp .line-itin .itin-body {
  padding: 20px 0px;
}

.otp .line-itin .place-row {
  display: table;
  width: 100%;
}


/* Departure/arrival time (1st column in table) */

.otp .line-itin .time {
  display: table-cell;
  width: 60px;
  font-size: 14px;
  color: #999999;
  text-align: right;
  padding-right: 4px;
  padding-top: 1px;
  vertical-align: top;
}

/* The place icon and line itself (2nd column in table) */
.otp .line-itin .line-container {
  position: relative;
  display: table-cell;
  width: 20px;
  max-width: 20px;
}

.otp .line-itin .place-icon-group {
  position: absolute;
  font-size: 18px;
  left: -8px;
  top: -7px;
  z-index: 20;
}

.otp .line-itin .leg-line {
  position: absolute;
  top: 11px;
  bottom: -11px;
  z-index: 10;
}

/*@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .otp .line-itin .line-container {
    overflow: hidden; // hack for IE to render table cell correctly.
  }

  .otp .line-itin .leg-line {
    height: 1000px; // hack for IE to render table cell correctly.
  }
}*/

.otp .line-itin .leg-line-walk {
  left: 6px;
  right: 6px;
  background: radial-gradient(ellipse at center, #87cefa 40%, transparent 10%);
  background-size: 12px 12px;
  background-repeat: repeat-y;
  background-position: center -5px;
}

.otp .line-itin .leg-line-bicycle {
  left: 7.5px;
  right: 7.5px;
  background: repeating-linear-gradient(
    0deg,
    red,
    red 8px,
    white 8px,
    white 12.5px
  );
}

.otp .line-itin .leg-line-car {
  left: 7.5px;
  right: 7.5px;
  background: repeating-linear-gradient(
    0deg,
    grey,
    grey 8px,
    white 8px,
    white 12.5px
  );
}

.otp .line-itin .leg-line-micromobility {
  left: 7.5px;
  right: 7.5px;
  background: repeating-linear-gradient(
    0deg,
    #f5a729,
    #f5a729 8px,
    white 8px,
    white 12.5px
  );
}

.otp .line-itin .leg-line-transit {
  left: 5px;
  right: 5px;
  background-color: gray;
}

/* Place/Leg details (3rd column in table) */

.otp .line-itin .place-details {
  font-size: 13px;
  display: table-cell;
  padding-top: 1px;
}

.otp .line-itin .place-name {
  font-size: 18px;
  line-height: 20px;
  padding-left: 4px;
  font-weight: 500;
  color: black;
}

.otp .line-itin .place-subheader {
  font-size: 12px;
  padding-left: 4px;
  padding-top: 1px;
  font-weight: 300;
  color: gray;
}

.otp .line-itin .interline-dot {
  position: relative;
  float: left;
  margin-left: -13.5px;
  z-index: 25;
  color: #fff;
}

.otp .line-itin .interline-name {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

/* Leg body general */

.otp .line-itin .leg-body {
  padding: 12px 0px 18px 4px;
  font-size: 13px;
  color: #999999;
}

.otp .line-itin .summary {
  cursor: pointer;
}

.otp .line-itin .leg-body .icon {
  height: 24px;
  width: 24px;
  float: left;
  margin-right: 6px;
}

.otp .line-itin .leg-body .leg-description {
  display: table;
}

.otp .line-itin .leg-body .leg-description > div {
  display: table-cell;
  vertical-align: middle;
}

/* Leg steps (for turn-by-turn) */

.otp .line-itin .leg-body .steps-header {
  font-size: 13px;
  margin-top: 10px;
  color: #999999;
  font-style: normal;
  display: inline-block;
}

.otp .line-itin .leg-body .step-row {
  font-size: 13px;
  margin-top: 8px;
  color: #999999;
  font-style: normal;
}

/* Transit leg details */

.otp .line-itin .leg-body .route-name {
  color: #999999;
  margin-top: 5px;
}

.otp .line-itin .leg-body .route-short-name {
  display: inline-block;
  background-color: #0f6aac;
  padding-top: 1px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  margin-right: 6px;
  text-align: center;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  border: 1px solid white;
  box-shadow: 0 0 0.25em #000;
  margin-right: 8px;
}

.otp .line-itin .leg-body .route-long-name {
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
}

.otp .line-itin .leg-body .transit-leg-details {
  margin-top: 5px;
}

.otp .line-itin .leg-body .agency-info {
  margin-top: 0px;
  margin-bottom:0x;
  margin-left:16px;
  margin-right:16px;

}

.otp .line-itin .leg-body .transit-leg-details .header {
  cursor: pointer;
  color: #999999;
  font-size: 13px;
}

/* Intermediate stops */

.otp .line-itin .leg-body .transit-leg-details .intermediate-stops .stop-row {
  z-index: 30;
  position: relative;
}

.otp .line-itin .leg-body .transit-leg-details .intermediate-stops .stop-marker {
  float: left;
  margin-left: -17px;
  color: white;
}

.otp .line-itin .leg-body .transit-leg-details .intermediate-stops .stop-name {
  color: #999999;
  font-size: 14px;
  margin-top: 2px;
  margin-bottom:2px;
  margin-left:16px;
  margin-right:16px;
}

/* Transit alerts */

.otp .line-itin .leg-body .transit-alerts-toggle {
  display: inline-block;
  margin-top: 8px;
  color: #D14727;
  font-weight: 400;
  cursor: pointer;
}

.otp .line-itin .leg-body .transit-alerts {
  margin-top: 3px;
}

.otp .line-itin .leg-body .transit-alerts .transit-alert {
  margin-top: 5px;
  background-color: #eee;
  padding: 8px;
  color: black;
  border-radius: 4px;
}

.otp .line-itin .leg-body .transit-alerts .transit-alert .alert-icon {
  float: left;
  font-size: 18px;
}

.otp .line-itin .leg-body .transit-alerts .transit-alert .alert-header {
  font-size: 14px;
  margin-left: 30px;
  font-weight: 600;
}

.otp .line-itin .leg-body .transit-alerts .transit-alert .alert-body {
  font-size: 12px;
  margin-left: 30px;
  /* white space pre-wrap is required to render line breaks correctly. */
  white-space: pre-wrap;
}

.otp .line-itin .leg-body .transit-alerts .transit-alert .effective-date {
  margin-top: 5px;
  margin-left: 30px;
  font-size: 12px;
  font-style: italic;
}

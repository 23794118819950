.otp.mobile .mobile-padding {
  padding: 10px;
}

.otp .mobile-bottom-button-container {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  height: 40px;
}

/* Navbar settings */

.otp.mobile .navbar {
  margin-bottom: 0;
  border: none;
}

.otp .navbar .mobile-header {
  position: fixed;
  top: 5px;
  left: 50px;
  right: 50px;
  text-align: center;
}

.otp .navbar .mobile-header-text {
  color: white;
  padding-top: 9px;
  font-size: 18px;
  display: inline-block;
}

.otp .navbar .mobile-header-action {
  display: inline-block;
}

.otp .navbar .mobile-header-action > button {
  color: white;
}

.otp .navbar .mobile-back {
  position: fixed;
  top: 12px;
  left: 15px;
  color: white;
  font-size: 18px;
}

.otp .navbar .mobile-close {
  position: fixed;
  top: 4px;
  right: 6px;
  color: white;
  font-size: 18px;
}

.otp .navbar-brand {
  padding: 7px 10px;
}

/* Welcome screen */

.otp.mobile .welcome-location {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  height: 60px;
}

.otp.mobile .welcome-map {
  position: fixed;
  top: 110px;
  left: 0;
  right: 0;
  bottom: 0;
}

/* Location search screen */

.otp.mobile .location-search {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
}

/* Main search screen */

.otp.mobile .search-settings {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  height: 250px;
}

.otp.mobile .search-map {
  position: fixed;
  top: 300px;
  left: 0;
  right: 0;
  bottom: 0;
}

/* Detailed options screen */

.otp.mobile .options-main-content {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 55px;
  overflow-y: auto;
  overflow-x: hidden;
}

.otp.mobile .options-lower-tray {
  position: fixed;
  height: 55px;
  left: 0;
  right: 0;
  bottom: 0;
}

/* Results screen: normal display */

.otp.mobile .mobile-narrative-header {
  position: fixed;
  height: 40px;
  left: 0;
  right: 0;
  background-color: #444;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  padding-top: 5px;
}

.otp.mobile .mobile-narrative-container {
  position: fixed;
  bottom: 20px;
  right: 0;
  left: 0;
  padding: 8px 12px;
}

.otp.mobile .dots-container {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  height: 20px;
  text-align: center;
}

.otp.mobile .dots-container .dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #ddd;
  margin: 0px 3px;
  vertical-align: 2px;
}

.otp.mobile .dots-container .dot.active {
  background-color: #888;
}

.otp.mobile .results-map {
  position: fixed;
  top: 100px;
  left: 0;
  right: 0;
  bottom: 140px;
}

/* Results screen: error display */

.otp.mobile .results-error-map {
  position: fixed;
  top: 100px;
  left: 0;
  right: 0;
  height: 200px;
}

.otp.mobile .results-error-message {
  position: fixed;
  top: 300px;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 12px;
}

/* User Settings */

.otp.mobile .user-settings {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: white;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  margin-bottom: 18px;
  height: 165px;
  z-index: 999999;
  overflow-y: scroll;
  border-radius: 5px;
}

/* Stop/Trip/Route Viewer screens */

.otp.mobile .viewer-container {
  position: fixed;
  top: 250px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
}

.otp.mobile .viewer-map {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  height: 200px;
}

:root {
  --hover-color: #ffe;
}

.otp .options > .header {
  margin-top: 20px;
  margin-bottom: 12px;
  font-size: 16px;
}

/* REALTIME ALERT */

.otp .realtime-alert {
  padding: 12px 20px;
  margin-bottom: 20px;
  background-color: #fdf8d6;
}

.otp .simple-realtime-annotation {
  color: #888;
  font-size: 14px;
  text-align: center;
  padding: 6px;
  background-color: #fcf9d3;
}

/*TODO: remove? no longer used in realtime annotation */
.otp .realtime-alert > .icon-container {
  /*color: red;*/
  float: left;
  font-size: 40px;
}

.otp .realtime-alert > .content {
  /*margin-left: 60px;*/
}

.otp .realtime-alert > .content > .header {
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
}

.otp .realtime-alert > .content > .text {
  font-size: 12px;
  margin: 8px 0;
}

.otp .realtime-alert > .content > .toggle-realtime {
  margin-top: 10px;
}


/* CAROUSEL HEADER */

.otp .options > .carousel-header > .carousel-button {
  width: 12.5%;
}

.otp .options > .carousel-header > .carousel-header-text {
  display: inline-block;
  width: 75%;
}

/* LEG DIAGRAM PREVIEW */

.otp .leg-diagram-preview {
  position: relative;
  width: 75%;
  margin: 0px 4px;
  font-style: normal;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  border: #fff solid 1px;
}

.otp .leg-diagram-preview:hover {
  border-color: #d1d5da;
  background-color: #f6f8fa;
}

.otp .leg-diagram-preview.on:hover {
  border-color: #d1d5da;
  background-color: #f6f8fa;
}

.otp .leg-diagram-preview.on {
  border-color: #d1d5da;
  background-color: #eee;
}

.otp .leg-diagram-preview .diagram {
  padding: 2px;
}

.otp .leg-diagram-preview .diagram .diagram-title {
  font-size: small;
  text-align: center;
}

.otp .leg-diagram-preview .expand-button-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
}

.otp .leg-diagram-preview .expand-button {
  width: 30px;
  height: 30px;
  fontSize: 16px;
}

/* TRIP TOOLS ROW */
.otp .trip-tools {
  margin-top: 10px;
  text-align: center;
}

.otp .trip-tools .button-container {
  display: inline-block;
  padding: 3px;
}

.otp .trip-tools .tool-button {
  font-size: 12px;
  width: 110px;
  padding: 4px;
}

.otp .trip-tools .dropdown-menu {
  font-size: 12px;
  text-align: left;
  padding: 4px;
}

.otp .trip-tools .dropdown-menu > li > a {
  padding: 3px 5px;
}

/* TABBED ITINERARIES */

.otp .tabbed-itineraries {
  margin-top: 10px;
}

.otp .tabbed-itineraries .tab-row {
  text-align: center;
  margin-bottom: 10px;
}

.otp .tabbed-itineraries .tab-button {
  width: 100px;
  margin: 5px;
  padding: 2px;
  border-radius: 0;
  outline: none;
  vertical-align: top;
}

.otp .tabbed-itineraries .tab-button:active {
  background-color: #f8f8f8;
}

.otp .tabbed-itineraries .tab-button .title {
  display: inline-block;
  padding-bottom: 1px;
  border-bottom: 3px solid transparent;
  margin-bottom: 3px;
}

.otp .tabbed-itineraries .tab-button .details {
  font-size: 12px;
  color: gray;
}

.otp .tabbed-itineraries .tab-button:hover .title {
  border-bottom: 3px solid #ddd;
}

.otp .tabbed-itineraries .tab-button.selected .title {
  border-bottom: 3px solid gray;
  font-weight: bold;
}

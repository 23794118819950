.collapsible {
  
    color: black;
    cursor: pointer;
    padding: 12px;
    border: none;
    outline: none;
    font-size: 15px;
    border-bottom:1px solid lightgrey;
    display: flex;
    justify-content: space-between;
    min-height: 70px;
    vertical-align:middle;
    align-content: center;
    align-items: center;
    max-width: 100vw;

}
.active {
   
    border-bottom:0px solid lightgrey;
}
.content {
    display: none;
    overflow: scroll;
    background-color: #f1f1f1;
    border-bottom:1px solid lightgrey;
    max-width: 95vw;
   
}

.price-header {
    background: lightgray;
    border-radius: 4px;
    vertical-align: middle;
    min-width: 70px;
    align-content: center;
    align-self: center;
    height: 100%;
    font-weight: 500;
}

.currency-td {
    vertical-align: middle;
    align-content: center;
    align-self: center;
    text-align: center;
    
}
.notsold-td {
    vertical-align: middle;
    align-content: center;
    align-self: center;
    text-align: center;
    color: gray;
}
.prices-td {
    border:none;
    border-spacing:0;
    border-bottom:1px solid darkgray;
    align-content: center;
    align-self: center;
    text-align: center;
}
.prices-tr {
    border-bottom:0.5px solid lightgray;
    border-spacing:0;
}
.prices-th {
    margin:8px;
    border:none;
    border-spacing:0;
}
.prices-table {
    width: 100%;
    border: 10px;
    overflow-x: scroll;
    border-spacing:4px 4px;
    padding:8px;
}



.strikethrough {
    position: relative;
    padding-inline:4px;
    font-size: 0.9rem;
  }
  .strikethrough:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 2px solid;
    border-color: rgba(255, 0, 0, .8);
  
    -webkit-transform:rotate(-20deg);
    -moz-transform:rotate(-20deg);
    -ms-transform:rotate(-20deg);
    -o-transform:rotate(-20deg);
    transform:rotate(-20deg);
  }
  

/**
 * Transitive Base Styles
 */

.Transitive {
  overflow: hidden;
}

/**
 * Schematic Map
 */

.Transitive > .schematic-map {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

/**
 * Labels
 */

.transitive-stop-label,
.transitive-multi-label,
.transitive-place-label {
  text-shadow:
   -2px -2px 0 #fff,
    2px -2px 0 #fff,
    -2px 2px 0 #fff,
     2px 2px 0 #fff;
}

.transitive-segment-label {
  fill: #fff;
}

/**
 * Label Container
 */

.transitive-segment-label-container {
  fill: #008;
}

/**
 * Lines
 */

.transitive-line {
  stroke-linecap: round;
  fill: none;
}

/**
 * Places
 */

.transitive-place-circle {
  fill: #fff;
  stroke: #333;
  stroke-width: 2px;
}

/**
 * Stops
 */

.transitive-stop-marker-pattern,
.transitive-stop-marker-merged,
.transitive-multipoint-marker-merged {
  fill: #fff;
  stroke: #333;
  stroke-width: 1px;
}

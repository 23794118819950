/* ITINERARY OPTION */

.otp .option.default-itin button:focus {
  outline: none;
}

.otp .option.default-itin {
  margin-bottom: 8px;
  margin-top:4px;
  overflow-x: scroll;
}

.otp .option.default-itin::-webkit-scrollbar {
  display: block;
  width: 0px;
  height: 0px;
}

.otp .option:hover .default-itin {
  background-color: var(--hover-color);
}

.otp .option.active .default-itin {
  background-color: #EAF3F2;
}

.otp .option.default-itin > .header {
  font-size: 16px;
  cursor: pointer;
}

.otp .option.default-itin > .header > .title {
  font-weight: bold;
}

.otp .option.default-itin > .header > .summary {
  text-align: center;
  margin: 8px 0px;
  display:flex;
}



.otp .option.default-itin > .header > .summary > .mode-block {
  height: 18px;
  width: 18px;
}

.otp .option.default-itin > .header > .summary > .arrow-block {
  font-size: 13px;
  margin-right:-4px;
  margin-left:-4px;
  margin-bottom:-8px
}

/* ITINERARY LEG */

.otp .option.default-itin .leg {
  background-color: #fff;
  padding: 10px;
  margin-bottom: 8px;
  border: 1px solid #bbb;
  border-radius: 0px;
}

.otp .option.default-itin .leg:hover {
  background-color: var(--hover-color);
}

.otp .option.default-itin .leg.active {
  background-color: #fff;
}

.otp .option.default-itin .leg > .header {
  cursor: pointer;
  padding: 0;
}

.otp .option.default-itin .transit-leg .header .mode-icon-container {
  float: left;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  vertical-align: middle;
  align-content: center;
}



.otp .option.default-itin .skew {
  transform: skew(20deg);
  -webkit-transform: skew(20deg);
}

.otp .option.default-itin .unskew {
  transform: skew(-20deg);
  -webkit-transform: skew(-20deg);
}

.otp .option.default-itin .short-name {
 
   vertical-align: middle;
   font-size: 14px;
   font-weight: 700;
   color: #fff;
   padding: 3px 6px 3px 6px;
   margin:4px;


}


.otp .option.default-itin .route-short-name {
 
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  display: inline-block;
  padding: 0 3px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: bottom;
  direction: inherit;
  min-width:20px;

}


.otp .option.default-itin .route-color-wrap {
display: inline-block;
    padding: 0 3px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: bottom;
    direction: inherit;
    margin-left: 2px;
    margin-right: 2px;
    padding-top: 0px;
    padding-bottom: 5px;
}


.otp .option.default-itin .transit-leg .route-long-name {
  vertical-align: middle;
  line-height: 20px;
  font-size: 18px;
  font-weight: 500;
  font-family: 'Rubik'
}

.otp .option.default-itin .transit-leg-body {
  display: table;
  margin-top: 10px;
  width: 100%;
}

.otp .option.default-itin .transit-leg-body .from-row,
.otp .option.default-itin .transit-leg-body .trip-details-row,
.otp .option.default-itin .transit-leg-body .to-row {
  display: flex;
}

.otp .option.default-itin .transit-leg-body .time-cell {
  display: flex;
  width: 40px;
  font-size: 10px;
  vertical-align: top;
  text-align: center;
  padding-top: 2px;
  min-width: 40px;
  max-width: 40px;
  align-self:center;
}

.otp .option.default-itin .transit-leg-body .trip-line-cell {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  min-width: 16px;
  max-width: 16px;
}

.otp .option.default-itin .transit-leg-body .trip-line-top {
  position: absolute;
  top: 10px;
  bottom: 0;
  width: 4px;
  background-color: #888;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
}

.otp .option.default-itin .transit-leg-body .trip-line-middle {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: #888;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
}

.otp .option.default-itin .transit-leg-body .trip-line-bottom {
  position: absolute;
  top: 0px;
  height: 10px;
  width: 4px;
  background-color: #888;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
}

.otp .option.default-itin .transit-leg-body .trip-line-stop {
  position: absolute;
  top: 8px;
  left: -15px;
  height: 4;
  width: 8;
  background-color: #888;
}

.otp .option.default-itin .transit-leg-body .stop-bubble {
  position: absolute;
  top: 4px;
  width: 12px;
  height: 12px;
  border: 2px solid black;
  background-color: #fff;
  border-radius: 8px;
}

.otp .option.default-itin .transit-leg-body .stop-name-cell {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 2px;
  margin-left:16px;
  margin-right:16px;
}

.otp .option.default-itin .transit-leg-body .trip-details-cell {
  display: table-cell;
  padding: 16px 0px 16px 8px;
}

.otp .option.default-itin .leg .stop-count {
  cursor: pointer;
}

.otp .option.default-itin .leg .stop-list {
  cursor: pointer;
  list-style: none;
}

.otp .option.default-itin .leg .alert-item {
  padding-left: 22px;
}

.otp .option.default-itin .leg .stop-list .stop-item {
  position: relative;
  font-size: 13px;
  line-height: 15px;
  padding-top: 4px;
}

.otp .option.default-itin .leg .info-item {
  font-size: 11px;
  font-style: italic;
  line-height: 13px;
  margin-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
}

/* ITINERARY STEP */

.otp .option.default-itin .step {
  cursor: pointer;
  background-color: #eee;
  margin-bottom: 4px;
  padding: 4px;
  font-size: x-small;
}

.otp .option.default-itin .step:hover {
  background-color: var(--hover-color);
}

.otp .option.default-itin .step.active {
  background-color: #ccc;
  /*border: 1px dotted;*/
}

.otp .option.default-itin .step .step-distance {
  float: right;
}

.otp .option.default-itin .step .step-text {
  padding-right: 4px;
  padding-left: 4px;
}



@import url(../node_modules/font-awesome/css/font-awesome.css);
@import url(./lib/transitive-js/lib/transitive.css);

@import url(./lib/components/app/app.css);
@import url(./lib/components/transit-prices/style.css);
@import url(./lib/components/map/map.css);
@import url(./lib/components/form/form.css);
@import url(./lib/components/narrative/narrative.css);
@import url(./lib/components/narrative/default/itinerary.css);
@import url(./lib/components/narrative/line-itin/itinerary.css);
@import url(./lib/components/mobile/mobile.css);
@import url(./lib/components/viewers/viewers.css);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Rubik', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-size-adjust: none !important;
  -webkit-text-size-adjust: none !important;
  -moz-text-size-adjust: none !important;
  -ms-text-size-adjust: none !important;
  background-color: #fff;
}

::-webkit-scrollbar {
  width: 0px;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.otp .navbar {
  border-radius: 0;
  background-color: #444;
}

.otp .navbar .container {
  margin-left: 0;
}

.otp .navbar-brand {
  padding: 7px 10px;
}

.otp .navbar-title {
  color: #fff;
  font-size: 24px;
  padding-top: 7px;
}

.otp.mobile .navbar-title {
  padding-top: 2px;
}

.main-row {
  position: fixed;
  top: 74px;
  bottom: 30px;
  left: 0px;
  right: 0px;
  padding: 0px 0px;
}

.sidebar {
  height: 100%;
  padding: 20px;
}

.map-container {
  height: 100%;
  margin: 0px;
  padding: 0px;
}





/* Hide IE/Edge clear button in text input fields. */
input[type=text]::-ms-clear {
  display: none;
}

/* Buttons */

button.header, button.step, .clear-button-formatting  {
background: transparent;
color: inherit;
border: 0;
text-align: inherit;
text-decoration: none;
padding: 4px;
margin: 0;
cursor: pointer;
}

.clear-button-formatting:active {
background: none;
box-shadow: none;
}

.clear-button-formatting:focus {
background: none;
}

.clear-button-formatting:hover {
background: none;
}

button.header, button.step {
width: 100%;
}

